
import { useCityRelTown } from "@/network/chinaArea";

import { baseRule } from "@/model/userFormRules.ts";
import { useUser, userTypeDic, checkPassword } from "@/network/user";
import {
  defineComponent,
  toRefs,
  ref,
  reactive,
  watch,
  Ref,
  PropType,
  computed,
  toRef,
  onMounted,
} from "vue";
import settings from "@/settings";
export default defineComponent({
  props: {
    setting: { type: Object },
  },
  setup(props, { emit }) {
    const tabActived = ref("1");
    const { visible, id } = toRefs(props.setting as any);
    const [isLoading, form, find, , , , , changePassword, baseSave] =
      useUser(id);
    const [dicCity, dicTown] = useCityRelTown(
      toRef(form, "city_ID"),
      toRef(form, "county_ID")
    );

    const validatePassOld = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入原密码"));
      } else {
        checkPassword(id.value, value).then((flag) => {
          if (flag) {
            callback();
          } else {
            callback(new Error("原密码输入不正确"));
          }
        });
      }
    };
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else {
        if (passwordForm.confirmPassword !== "") {
          refPasswordForm.value.validateField("confirmPassword");
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入确认密码"));
      } else if (value !== passwordForm.newPassword) {
        callback(new Error("两次密码输入不正确!"));
      } else {
        callback();
      }
    };
    const passwordForm = reactive({
      oldPassword: null,
      newPassword: null,
      confirmPassword: null,
    });

    const passwordFormRules = {
      oldPassword: [
        { required: true, validator: validatePassOld, trigger: "blur" },
        {
          min: 6,
          max: 12,
          message: "长度应在6-12位之间",
          trigger: "blur",
        },
      ],
      newPassword: [
        { required: true, validator: validatePass, trigger: "blur" },
        {
          min: 6,
          max: 12,
          message: "长度应在6-12位之间",
          trigger: "blur",
        },
      ],
      confirmPassword: [
        { required: true, validator: validatePass2, trigger: "blur" },
        {
          min: 6,
          max: 12,
          message: "长度应在6-12位之间",
          trigger: "blur",
        },
      ],
    };
    const refForm = ref(null);
    const refPasswordForm = ref(null);

    watch(visible, (v) => {
      if (v) {
        find().then((p) => {
          setTimeout(() => {
            refPasswordForm.value.clearValidate();
            refForm.value.clearValidate();
          }, 1);
        });
      } else {
        tabActived.value = "1";
        passwordForm.oldPassword = null;
        passwordForm.newPassword = null;
        passwordForm.confirmPassword = null;
      }
    });

    const doSave = () => {
      return new Promise((resolve, reject) => {
        if (tabActived.value == "1") {
          refPasswordForm.value.validate((valid) => {
            if (valid) {
              changePassword(id.value, passwordForm).then((flag) => {
                if (flag) {
                  resolve("success");
                //   passwordForm.oldPassword = null;
                //   passwordForm.newPassword = null;
                //   passwordForm.confirmPassword = null;
                  visible.value = false;
                  // setTimeout(() => location.reload(), 1000);
                } else {
                  reject("error");
                }
              });
            } else {
              reject("error");
            }
          });
        } else {
          refForm.value.validate((valid) => {
            if (valid) {
              baseSave(id.value, form).then(() => {
                resolve("success");
                setTimeout(() => location.reload(), 1000);
              });
            } else {
              reject("error");
            }
          });
        }
      });
    };

    return {
      passwordForm,
      userTypeDic: userTypeDic(),
      refForm,
      refPasswordForm,
      isLoading,
      visible,
      form,
      doSave,
      rules: baseRule,
      passwordFormRules,
      dicCity,
      dicTown,
      tabActived,
    };
  },
});
