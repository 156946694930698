import { RouteRecordRaw } from "vue-router";
import Layout from "@/views/layout/Index";
import RouterView from "@/views/layout/Router-View.vue";
import store from "@/store/index";
import roles from "@/types/roles";

const router: RouteRecordRaw = {
  path: "/ReportAndReview",
  component: Layout,
  meta: { title: "网报网审", topMenu: true, icon: "el-icon-s-claim" },
  name: "project",
  children: [
    {
      path: "/ReportAndReview/Things",
      component: () => import("@/views/things/index.vue"),
      name: "ReportAndReviewThingsIndex",
      meta: {
        title: () => {
          return store.getters.user.inRoles(roles.prov)
            ? "行政审批事项审核"
            : "行政审批事项申报";
        },
        roles: [roles.applyUnit, roles.city, roles.prov],
      },
    },
    {
      path: "/ReportAndReview/Things/Manage/:id?/:createCode?",
      component: () => import("@/views/things/manage.vue"),
      name: "ReportAndReviewThingsManage",
      meta: {
        title: "行政审批事项",
        roles: [roles.applyUnit, roles.city, roles.prov],
        hidden:true
      },
      props:true
    },
    {
      path: "/ReportAndReview/Things/Detail/:id",
      component: () => import("@/views/things/detail.vue"),
      name: "ReportAndReviewThingsDetail",
      meta: {
        title: "行政事项审批查看",
        roles: [roles.applyUnit, roles.city, roles.prov],
        hidden:true
      },
      props:true
    },
    {
      path: "/ReportAndReview/Setup/Manage/:id?",
      component: () => import("@/views/reportReview/manage.vue"),
      name: "ReportAndReviewSetupManage",
      meta: {
        hidden: true,
        title: "计划书详情",
        roles: [roles.applyUnit, roles.city, roles.prov],
      },
      props: true,
    },
    {
      path: "/ReportAndReview/Setup",
      component: () => import("@/views/reportReview/setup/reviewList.vue"),
      name: "ReportAndReviewSetupIndex",
      meta: {
        title: () => {
          return store.getters.user.inRoles(roles.prov)
            ? "评审计划书"
            : "报计划书";
        },
        roles: [roles.applyUnit, roles.city, roles.prov],
      },
    },
    {
      path: "/ReportAndReview/Setup/Detail/:id?",
      component: () => import("@/views/reportReview/setup/detail.vue"),
      name: "ReportAndReviewSetupView",
      meta: {
        hidden: true,
        title: "计划书详情",
        roles: [roles.applyUnit, roles.city, roles.prov],
      },
      props: true,
    },
    {
      path: "/ReportAndReview/Setup/FileSignManage/:id?",
      component: () => import("@/views/reportReview/setup/fileSignManage.vue"),
      name: "ReportAndReviewSetupFileSignManage",
      meta: {
        hidden: true,
        title: "汇总申请",
        roles: [roles.city, roles.prov],
      },
      props: true,
    },
    {
      path: "/ReportAndReview/Plan",
      component: () => import("@/views/reportReview/plan/reviewList.vue"),
      name: "ReportAndReviewPlanIndex",
      meta: {
        title: () => {
          return store.getters.user.inRoles(roles.prov)
            ? "方案备案"
            : "方案备案";
        },
        roles: [roles.applyUnit, roles.city, roles.prov],
      },
    },
    {
      path: "/ReportAndReview/Plan/Manage/:id?",
      component: () => import("@/views/reportReview/plan/manage.vue"),
      props:true,
      name: "ReportAndReviewPlanManage",
      meta: {
        hidden: true,
        title: "方案备案详情",
        roles: [roles.applyUnit, roles.city, roles.prov],
      },
    },
    {
      path: "/ReportAndReview/Plan/Detail/:id?",
      props:true,
      component: () => import("@/views/reportReview/plan/detail.vue"),
      name: "ReportAndReviewPlanView",
      meta: {
        hidden: true,
        title: "方案备案详情",
        roles: [roles.applyUnit, roles.city, roles.prov],
      },
    },
  ],
};

export default router;
