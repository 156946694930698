import { RouteRecordRaw } from "vue-router";
import Layout from "@/views/layout/Index";
import roles from "@/types/roles";
const router: RouteRecordRaw = {
  path: "/HeritageUnit",
  component: Layout,
  name: "HeritageUnitIndex",
  meta: {
    title: "不可移动文物库",
    topMenu: true,
    icon: "el-icon-office-building",
    roles: [roles.applyUnit, roles.city, roles.prov],
  },

  children: [
    {
      path: "/HeritageUnit/BaseManage/:id?",
      meta: { title: "不可移动文物编辑", hidden: true },
      component: () => import("@/views/heritageUnit/baseManage.vue"),
      props: true,
    },
    {
      path: "/HeritageUnit/index",
      meta: { title: "不可移动文物" },
      component: () => import("@/views/heritageUnit/index.vue"),
    },
    {
      path: "/HeritageUnit/else",
      meta: { title: "文博机构" },
      component: () => import("@/views/heritageUnit/elseIndex.vue"),
    },
    {
      path: "/HeritageUnit/create",
      meta: { title: "添加单位或机构", roles: [roles.prov] },
      component: () => import("@/views/heritageUnit/create.vue"),
    },
  ],
};

export default router;
