<template>
  <div class="logo">
    <img class="logo-unfold" :src="logo" />
    <h1
      style="
        font-family: 'Helvetica Neue', Helvetica, 'PingFang SC',
          'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
        font-size: 30px;
        color: white;font-weight:normal
      "
    >
      山东省文物保护项目申报管理系统
    </h1>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "logo",
  setup() {
    return {
      logo: require("@/assets/images/LogoWhite.png"),
    };
  },
});
</script>
<style lang="scss" scoped>
</style>
