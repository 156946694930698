import {
  createRouter,
  createWebHistory,
  RouteRecordName,
  RouteRecordRaw,
} from "vue-router";
import Layout from "@/views/layout/Index";

import project from "./modules/project";
import fund from "./modules/fund";
import fundReview from "./modules/fundReview";
import projectDo from "./modules/projectDo";
import codeFile from "./modules/officialDoc";
import heritageUnit from "./modules/heritageUnit";
import systemManage from "./modules/systemManage";
import analysis from "./modules/analysis";
import historyProject from "./modules/historyProject";
export const constantRoutes: Array<RouteRecordRaw> = [
  {
    path: "/redirect",
    component: Layout,
    meta: { hidden: true },
    children: [
      {
        path: "/redirect/:path*",
        component: () => import("@/views/redirect/index"),
      },
    ],
  },
  {
    name: "register",
    path: "/register",
    component: () => import("@/views/register/index"),
    meta: { hidden: true },
  },
  {
    name: "login",
    path: "/login",
    component: () => import("@/views/login/index"),
    meta: { hidden: true },
  },
  {
    path: "/auth-redirect",
    component: () => import("@/views/login/auth-redirect"),
    meta: { hidden: true },
  },
  {
    path: "/404",
    component: () => import("@/views/error-page/404"),
    meta: { hidden: true },
  },
  {
    path: "/401",
    component: () => import("@/views/error-page/401"),
    meta: { hidden: true },
  },
  {
    path: "/",
    component: Layout,
    name: "index",
    meta: { title: "首页", hidden: true },
    props: () => {
      return { crumbs: false };
    },
    children: [
      {
        path: "",
        component: () => import("@/views/Home"),
      },
    ],
  },
];

export const asyncRoutes = [
  /** when your routing map is too long, you can split it into small modules **/
  project,
  fund,
  fundReview,
  projectDo,
  historyProject,
  codeFile,
  heritageUnit,
  analysis,
  systemManage,
  // 404 page must be placed at the end !!!
  { path: "/:catchAll(.*)", name: "404", redirect: "/404", hidden: true },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: constantRoutes,
});

export function resetRouter() {
  router.getRoutes().forEach((p) => {
    const name: RouteRecordName | undefined = p.name;
    if (name != null) router.removeRoute(name);
    else {
      const r = router.addRoute(p);
      r();
    }
  });
  constantRoutes.forEach((p) => {
    router.addRoute(p);
  });
}

//console.log(router.getRoutes());
export default router;
